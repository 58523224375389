import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CareersDetailsView from '../../components/CareersDetails';

const CareersDetails = ({ location, data }) => (
  <CareersDetailsView
    location={location}
    title="Senior Technical Architect - Software Careers | MCRO"
    description="Senior Technical Architect at MCRO. Be part of the best front-end team in Cluj Napoca, Romania"
    metaContent="architect,react, node, full stack, web, back-end, javascript, front-end, redux, es6, es7"
    id="technical-architect"
    jobTitle="Senior Technical Architect"
    jobDescription="This position is responsible for the Technology Design, Development and Delivery of
     a portfolio of strategic IT projects. This individual will work with various technology partners to
     define and drive strategic requirements and solutions across development, infrastructure, and testing
      teams and will be accountable for project deliverables, resource allocations, assignments, and the
       overall quality of the final product. We are looking for a self-motivated individual, passionate
        about technology and current with technology constant evolution."
    mailHref="mailto:jobs@mcro.tech?subject=React-Native Job Application"
    responsibilities={[
      'Be able to architect and drive application design and development for system components (Services/APIs and'
      + ' applications).',
      'Create technical designs (Activity Diagrams/Sequence Diagrams, API service specifications and message models)'
      + ' by working closely with Architecture, Technical Leads and other team members.',
      'Review business and non-functional requirements to capture and understand project requirements.',
      'Provide Technical support during Development, Testing, and Deployment of projects.',
      'Performs root cause analysis, efficient production support and maintenance/enhancements for existing'
      + ' applications.',
      'Experience leading individuals at multiple levels.',
      'Encourages the establishment of high performing and self-sustaining cross-functional teams.',
      'Experience with Agile development methodologies and Web and Service Oriented Architecture technologies.',
      'Able to guide a matrix development and delivery team to review business requirements and develop'
      + ' architectural design documents, system requirements, non-functional requirements, test plans'
      + ' / test cases, performance testing plans, and high/low level design documents.',
      'Ability to effectively communicate technical solutions and recommendations to senior level management.'

    ]}
    requirements={[
      '3+ years of experience in software design and architecture.',
      '7+ years of development experience on Javascript frameworks in building resilient and high performance,'
      + ' customer facing web applications.',
      'Extensive experience designing SOA services (SOAP & REST) and middleware integration for large distributed'
      + ' enterprise applications.',
      'Significant experience on multiple SDLC methodologies (Agile, Scrum, Kanban, Waterfall).',
      'Prior experience working with various Internet Security protocols (e.g., SSO, SAML, oAuth, CA technology'
      + ' stack, SiteMinder).',
      'BS Degree or equivalent work experience in a software engineering discipline.',
      'Proficiency in developing highly available applications using open source Javascript technologies and'
      + ' Frameworks such as React, React Native, Angular, Vue and Node.',
      'Experienced in AWS and/or Serverless Computing Proven ability to multi-task and manage several concurrent'
      + ' projects and initiatives.']}
    communicationSkills={[
      'Must be able to communicate with business/non-technical groups',
      'Must be able to rapidly build semi-working prototypes',
      'Must have the ability to work in a team',
      'Must be able to help communicate ideas and best practices to more junior developers',
      'Must be able to collaborate with wider groups on standards and best practices',
      'Must be able to work in an Agile operating model']}
    offerings={[
      'Competitive compensation',
      'Possibility to work remote when desired',
      'Work with the best professionals in the area',
      'Great work environment in our Cluj Napoca-Office',
      'Private healthcare insurance for employees'
    ]}
    imageElement={data}
  />
);

CareersDetails.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({})
};

CareersDetails.defaultProps = {
  data: null
};

export default CareersDetails;

export const query = graphql`
  query architectCareerPage {
    file(relativePath: { eq: "careers-details-banner.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
